import React from 'react';

import logo from '@/img/logo-base64.js';

const Footer = () => (
  <footer className="footer has-text-white-ter">
    <div className="content has-text-centered">
      <img
        src={logo}
        alt="Disney"
        style={{ width: '266px', height: '59px' }}
      />
    </div>
  </footer>
);

export default Footer;
