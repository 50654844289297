import React, { useState } from 'react';
import PropTypes from 'prop-types';

import SC from './style';
import useKeyPress from './useKeyPress';

const KEY_ESCAPE = 'Escape';

export const useDialog = (open) => {
  const [isDialogOpen, setIsOpen] = useState(open);
  const setDialogOpen = () => setIsOpen(true);
  const setDialogClose = () => setIsOpen(false);
  return { isDialogOpen, setDialogOpen, setDialogClose };
};

export const useKeyPressEscape = () => useKeyPress(KEY_ESCAPE);

const Dialog = ({ children, open, onBackdropClick, theme }) => {

  return (
    <SC.Dialog open={open}>
      <SC.DialogContainer>
        <SC.DialogBackdrop onClick={() => onBackdropClick()} />
        <SC.DialogContent theme={theme.content}>{children}</SC.DialogContent>
      </SC.DialogContainer>
    </SC.Dialog>
  );
};

Dialog.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool,
  onBackdropClick: PropTypes.func,
  theme: PropTypes.object,
};

Dialog.defaultProps = {
  open: false,
  onBackdropClick: () => {},
  theme: {
    content: {},
  },
};

export default Dialog;
