import styled from 'styled-components';

const Styled = {};

Styled.CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 20px;
  cursor: pointer;
  background: transparent;

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 8px;
    top: 0;
    display: block;
    width: 4px;
    height: 100%;
    border-radius: 5px;
    background: ${props => props.theme.color.primary};
  }

  &::before {
    transform: rotate(45deg);
    transform-origin: 50% 50%;
  }

  &::after {
    transform: rotate(-45deg);
    transform-origin: 50% 50%;
  }
`;

Styled.ArrowLeft = styled.div`
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 20px 10px 0;
  border-color: transparent ${props => props.theme.color.primary} transparent transparent;
`;

Styled.ArrowLeftWhite = styled(Styled.ArrowLeft)`
  border-right-color: ${props => props.theme.color.primaryInvert};
`;

Styled.ArrowRight = styled.div`
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 10px 20px;
  border-color: transparent transparent transparent ${props => props.theme.color.primary};
`;

Styled.ArrowRightWhite = styled(Styled.ArrowRight)`
  border-left-color: ${props => props.theme.color.primaryInvert};
`;

export default Styled;
