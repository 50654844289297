import React from 'react';
import styled from 'styled-components';

const Styled = {};

Styled.Dialog = styled.dialog`
  position: fixed;
  z-index: 999999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;

  /* Legacy browser support */
  display: none;
  &[open] {
    display: block;
  }
`;

Styled.DialogContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

Styled.DialogContent = styled(props => <div {...props} />)`
  position: relative;
  width: ${props => props.theme.width ? props.theme.width : '640px'};
  height: ${props => props.theme.height ? props.theme.height : 'auto'};
  max-width: calc(100% - 20px);
  padding: 40px 40px 20px;
  box-shadow: #666 0 1px 5px 1px;
  border-radius: 20px;
  background: white;
`;

// Use our own backdrop instead of native and polyfill
Styled.DialogBackdrop = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(29, 150, 215, 0.5);
`;

export default Styled;
