import styled from 'styled-components';

const Styled = {};

Styled.LanguageList = styled.ul`
  display: flex;
  justify-content: space-evenly;
  list-style: none;
  padding: 0;
  font-size: ${props => props.theme.font.size4};

  li {
    flex: 1;

    > span,
    > a {
      display: block;
      width: 100%;
      text-align: center;
    }
  }
`;

Styled.CurrentLanguageButton = styled.button`
  display: inline-block;
  white-space: nowrap;
  margin: 0;
  border: 0;
  font-size: ${props => props.theme.font.size6};
  color: ${props => props.theme.color.link};
  background: transparent;
  cursor: pointer;
`;

Styled.CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 20px;
  background: transparent;
  cursor: pointer;
`;

export default Styled;
