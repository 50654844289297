import React from 'react';
import { navigate } from '@reach/router';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import {
  SEARCH_FILTER_KEYWORD,
  SEARCH_REQUESTED,
} from '@/state/searchFilter/action';

import SC from './style';

const SearchBox = () => {
  const { locale: language } = useIntl();
  const keyword = useSelector(({ searchFilter: { keyword } }) => keyword);
  const dispatch = useDispatch();

  return (
    <SC.SearchBoxForm
      action={`/${language}/press-search/`}
      method="get"
      onSubmit={(event) => {
        event.preventDefault();
        navigate(`/${language}/press-search/?keyword=${keyword}`);
        dispatch({ type: SEARCH_REQUESTED });
      }}
    >
      <SC.SearchIcon />
      <SC.Input
        type="search"
        id="search-box"
        name="keyword"
        required
        defaultValue={keyword}
        onChange={({ target: { value: payload }}) => {
          dispatch({ type: SEARCH_FILTER_KEYWORD, payload });
        }}
      />
      <SC.Label htmlFor="search-box">
        <FormattedMessage id="navbar.searchbox" />
      </SC.Label>
    </SC.SearchBoxForm>
  );
};

export default SearchBox;
