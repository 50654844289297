/*
Hong Kong Disneyland OLCI Implementation Guide

In order to collect analytics on the Hong Kong Disneyland Resort site, several external javascript files and an inline loading script must be added
to each page. The external javascript files include our standard tag management system files and the Disney Parks & Resorts Digital (DPRD)
analytics framework bundled with a number of vendor scripts. DPRD primarily uses Adobe SiteCatalyst for compilation and reporting of site
analytics. However, we do not use SiteCatalyst-specific properties when gathering analytics data for our sites. Rather, we use a semantic
analytics model (SAM) as a data layer and then translate that data layer into the various vendor-specific properties that they each expect.
Essentially, implementing analytics for Hong Kong Disneyland Resort boils down to 2 tasks:
Including the necessary script files on each page.
Creating the SAM and passing it into the DPRD analytics framework.
*/

const SAMMap = {
  en: {
    common: {
      reportSuiteId: 'wdgwdprohkdlenusdev',
      marketingRegion: 'INTL',
      contentLanguage: 'en',
      siteSection: 'tools/custom/pressroom',
    },
    pressList: {
      pageId: 'en/pressrelease/',
    },
    press: {
      pageId: 'en/pressrelase/', // with suffix <YYYYMMDD_press release ID>
    },
    search: {
      pageId: 'en/pressrelase/search/', // with suffix <search keyword_number of result>
    },
  },
  'zh-hk': {
    common: {
      reportSuiteId: 'wdgwdprohkdlzhhkdev',
      marketingRegion: 'INTL',
      contentLanguage: 'zh_hk',
      siteSection: 'tools/custom/pressroom',
    },
    pressList: {
      pageId: 'zhhk/pressrelease/',
    },
    press: {
      pageId: 'zhhk/pressrelase/', // with suffix <YYYYMMDD_press release ID>
    },
    search: {
      pageId: 'zhhk/pressrelase/search/', // with suffix <search keyword_number of result>
    },
  },
};

export default SAMMap;
