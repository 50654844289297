import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';

import SC from './style';

import logo from '@/img/logo-base64';
import LanguageSwitcher from '@/components/LanguageSwitcher';
import SearchBox from '@/components/SearchBox';

const Navbar = injectIntl(({ intl }) => (
  <SC.NavBar
    role="navigation"
    aria-label="main-navigation"
  >
    <SC.NavContainer>
      <SC.ToolBox>
        <SC.VisitDisneyLink href={intl.formatMessage({ id: 'site.disneydotcom' })}>
          <FormattedMessage id="site.visit" />
        </SC.VisitDisneyLink>
        <SC.ToolBoxRight>
          <LanguageSwitcher />
          <SearchBox />
        </SC.ToolBoxRight>
      </SC.ToolBox>
      <SC.LogoLink to={`/${intl.locale}/press/`}>
        <SC.LogoImage src={logo} alt={intl.formatMessage({ id: 'site.name'})} />
      </SC.LogoLink>
    </SC.NavContainer>
  </SC.NavBar>
));

export default Navbar;
