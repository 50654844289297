import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import mainBackgroundRepeatX from '@/img/main-background-repeat-x.png';
import mainBackgroundStars from '@/img/main-background-stars.png';

const StyledWrapper = styled.div`
  position: relative;
  display: block;
  width: 100%;
  min-height: 600px;
  padding: 20px;
  background: ${props => props.theme.color.background} url(${mainBackgroundRepeatX}) top center repeat-x;
  overflow: hidden;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url(${mainBackgroundStars}) top center repeat-x;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    left: 50%;
    top: -380px;
    width: 100%;
    max-width: 940px;
    height: 800px;
    background-image: radial-gradient(ellipse at center, rgba(255, 255, 255, 0.95) 0, rgba(255, 255, 255, 0.5) 50%, rgba(71, 218, 255, 0) 70%);
    opacity: .8;
    transform: translateX(-50%);
  }
`;

const StyledMain = styled.main`
  position: relative;
  z-index: 2;
  display: block;
  width: 100%;
  max-width: 940px;
  margin: 20px auto;
  padding-bottom: 1px;
  border-radius: 12px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  background-color: #FFF;
`;

const PageMainContent = ({ children }) => (
  <StyledWrapper>
    <StyledMain>{children}</StyledMain>
  </StyledWrapper>
);

PageMainContent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageMainContent;
