import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from 'styled-components';

import Head from '@/components/Head';
import Layout from '@/components/Layout';

import favicon from '@/img/favicon.ico';
import messages from '@/languages/messages';
import defaultTheme from '@/theme/default';

// Polyfill Intl plural in IE 11
if (typeof Intl.PluralRules === 'undefined') {
  require('@formatjs/intl-pluralrules/polyfill-locales');
}

const App = ({ language, defaultLanguage, children }) => {
  return (
    <IntlProvider
      locale={language}
      messages={messages[language]}
      defaultLocale={defaultLanguage}>
      <Head>
        <link
          rel="icon"
          type="image/x-icon"
          href={favicon}
          sizes="16x16"
        />

        <meta name="theme-color" content="#1d96d7" />
      </Head>
      <ThemeProvider theme={defaultTheme}>
        <Layout>{children}</Layout>
      </ThemeProvider>
    </IntlProvider>
  );
};

App.propTypes = {
  language: PropTypes.string,
  defaultLanguage: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default App;
