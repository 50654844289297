import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const Styled = {};

Styled.NavBar = styled.nav`
  position: relative;
  padding: 10px 20px 0;
  box-shadow: 0 0 5px 2px #aaa;
  z-index: 10;
`;

Styled.NavContainer = styled.div`
  width: 100%;
  max-width: 940px;
  margin: 0 auto;
`;

Styled.ToolBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 25px;
  margin-bottom: 10px;
`;

Styled.VisitDisneyLink = styled.a`
  font-size: 12px;
  color: ${props => props.theme.color.light};
`;

Styled.ToolBoxRight = styled.div`
  display: flex;
  font-size: ${props => props.theme.font.size6};
`;

Styled.LogoLink = styled(props => <Link {...props} />)`
  display: inline-block;
`;

Styled.LogoImage = styled.img`
  width: 134px;
  height: 31px;
`;

export default Styled;
