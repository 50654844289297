import styled from 'styled-components';
import iconSprite from '@/img/icon-sprite.png';

const Styled = {};

Styled.SearchBoxForm = styled.form`
  position: relative;
  width: 160px;
  height: 24px;
  overflow: hidden;
  white-space: nowrap;
  border-radius: 10px;
  box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.3);
`;

Styled.SearchIcon = styled.div`
  position: absolute;
  left: 10px;
  top: 50%;
  display: block;
  width: 19px;
  height: 19px;
  margin-top: -6px;
  background: url(${iconSprite}) no-repeat;
  background-position: 0 -1553px;
`;

Styled.Input = styled.input`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0 10px 0 30px;
  border: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  background: transparent;

  &:focus + label,
  &:valid + label {
    opacity: 0;
  }
`;

Styled.Label = styled.label`
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  width: 100%;
  height: 100%;
  line-height: 24px;
  margin: 0;
  padding: 0 10px 0 30px;
  border: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  pointer-events: none;
`;

export default Styled;
