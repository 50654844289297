const deviceSize = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

export const device = {
  mobileS: `(min-width: ${deviceSize.mobileS})`,
  mobileM: `(min-width: ${deviceSize.mobileM})`,
  mobileL: `(min-width: ${deviceSize.mobileL})`,
  tablet: `(min-width: ${deviceSize.tablet})`,
  laptop: `(min-width: ${deviceSize.laptop})`,
  laptopL: `(min-width: ${deviceSize.laptopL})`,
  desktop: `(min-width: ${deviceSize.desktop})`,
  desktopL: `(min-width: ${deviceSize.desktop})`,

  above: {
    mobileS: `(min-width: ${deviceSize.mobileS})`,
    mobileM: `(min-width: ${deviceSize.mobileM})`,
    mobileL: `(min-width: ${deviceSize.mobileL})`,
    tablet: `(min-width: ${deviceSize.tablet})`,
    laptop: `(min-width: ${deviceSize.laptop})`,
    laptopL: `(min-width: ${deviceSize.laptopL})`,
    desktop: `(min-width: ${deviceSize.desktop})`,
    desktopL: `(min-width: ${deviceSize.desktop})`,
  },

  below: {
    mobileS: `(max-width: ${deviceSize.mobileS})`,
    mobileM: `(max-width: ${deviceSize.mobileM})`,
    mobileL: `(max-width: ${deviceSize.mobileL})`,
    tablet: `(max-width: ${deviceSize.tablet})`,
    laptop: `(max-width: ${deviceSize.laptop})`,
    laptopL: `(max-width: ${deviceSize.laptopL})`,
    desktop: `(max-width: ${deviceSize.desktop})`,
    desktopL: `(max-width: ${deviceSize.desktop})`,
  }
};

const font = {
  fontFamily: 'BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Microsoft Yahei UI Light", "Microsoft Yahei", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
  text: '#253b56',
  size1: '3rem',
  size2: '2rem',
  size3: '1.5rem',
  size4: '1.25rem',
  size5: '1rem',
  size6: '0.75rem',
};

const color = {
  primary: '#162f4a',
  primaryInvert: '#fff',
  primaryLight: '#ebf6fc',
  bodyColor: '#333',
  black: '#2b2523',
  white: '#fff',
  link: '#1994d7',
  light: '#78899e',
  borderLight: '#dee3e9',
  highlight: '#ffff80',
  background: '#1d96d7',
};

const theme = {
  color,
  font,
  device,
};

export default theme;
