import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedDate as ReactIntlFormattedDate } from 'react-intl';

const FormattedDate = (props) => {
  const intl = useIntl();

  // FIXME: Use hardcoded date string because locale keep as "en" when GatsbyJS build, don't know why
  if (intl.locale.indexOf('zh') === 0) {
    const date = new Date(props.value);
    return <>{`${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`}</>;
  }

  return (
    <ReactIntlFormattedDate
      {...props}
    />
  );
};

FormattedDate.propTypes = {
  value: PropTypes.any,
};

export default FormattedDate;
