import React from 'react';
import PropTypes from 'prop-types';

import SC from './style';
import Footer from '@/components/Footer';
import Navbar from '@/components/Navbar';

const Layout = ({ children }) => (
  <SC.Root>
    <Navbar />
    {children}
    <Footer />
  </SC.Root>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
