import React from 'react';
import { Link } from 'gatsby';
import { useIntl } from 'react-intl';
import { Location } from '@reach/router';

import Dialog, { useDialog, useKeyPressEscape } from 'react-dialog';
import SC from './style';
import Common from '@/components/commonStyle';

import { languages } from '@/languages/config';

const LanguageSwitcher = () => {
  const { locale: currentLanguage } = useIntl();
  const { label: currentLanguageLabel } = languages.find(({ value }) => value === currentLanguage);

  const { isDialogOpen, setDialogOpen, setDialogClose } = useDialog(false);
  const isKeyPressEscape = useKeyPressEscape();
  if (isKeyPressEscape && isDialogOpen) setDialogClose();

  return (
    <Location>
      {({ location: { pathname } }) => {
        const slug = pathname.replace(`/${currentLanguage}`, '');
        return (
          <>
            <SC.CurrentLanguageButton
              type="button"
              onClick={() => setDialogOpen()}>
              {currentLanguageLabel}
            </SC.CurrentLanguageButton>
            <Dialog
              open={isDialogOpen}
              onBackdropClick={() => setDialogClose()}>
              <Common.CloseButton
                type="button"
                onClick={() => setDialogClose()} />
              <SC.LanguageList>
                {languages.map(({ label, value }) => (
                  <li key={value}>
                    {value === currentLanguage
                      ? <span>{label}</span>
                      : <Link to={`/${value}${slug}`}>{label}</Link>
                    }
                  </li>
                ))}
              </SC.LanguageList>
            </Dialog>
          </>
        );
      }}
    </Location>
  );
};

export default LanguageSwitcher;
