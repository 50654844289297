import React from 'react';
import PropTypes from 'prop-types';
import SC from './style';

const PageHeader = ({ title, heroImage }) => (
  <SC.Header>
    <SC.Title>{title}</SC.Title>
    { heroImage &&
      <SC.HeroImage
        src={heroImage}
        alt={title}
      />
    }
  </SC.Header>
);

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  heroImage: PropTypes.string,
};

export default PageHeader;
