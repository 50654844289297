import _ from 'lodash';

const sendTrackingData = ({
  framework,
  tms,
  reportSuiteId,
  pageId,
  siteSection,
  events,
  selfServiceType,
  contentLanguage,
}) => {
  // eslint-disable-next-line
  console.log('🚨', pageId);
  if (framework) {
    framework.update({
      configuration: {
        SiteCatalyst: {
          reportSuiteId,
        },
        TestAndTarget: {
          globalMbox: false,
          dynamicMboxes: false,
        },
        GoogleRemarketing: {
          turnOff: true
        },
        WebsiteCustomAudiences: {
          turnOff: true
        },
        Facebook: {
          turnOff: true
        },
        GoogleHotels: {
          turnOff: true
        },
        Gtag: {
          turnOff: true
        },
        contentLanguage,
        contentLocale: contentLanguage,
      },
      site: 'HKDL',
      // Refer to Column I in the analytics requirements
      pageId,
      siteSection,
      // Refer to Column L in analytics requirements, butpopulate with semantic event name
      events,
      selfServiceType,
    });
  }
  if (tms) {
    tms.pageBottom();
  }
};

function trackPage(args) {
  if (typeof window === 'undefined')  return false;

  const framework = _.get(window, 'WDPRO.Analytics.Framework');
  const tms = _.get(window, '_satellite');

  if (framework && tms) {
    framework.reset();
    sendTrackingData({
      framework,
      tms,
      ...args,
    });
  } else {
    // Use polling to wait script loaded because of IE / Edge support
    setTimeout(() => { trackPage(args); }, 1000);
  }
}

export default trackPage;
