import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

const Head = ({
  title,
  description,
  children,
}) => {
  const { formatMessage, locale: language } = useIntl();
  return (
    <Helmet>
      <html lang={language} />
      <title>{`${title} - ${formatMessage({ id: 'site.name' })}`}</title>
      <meta name="description" content={description} />
      {children || null}
    </Helmet>
  );
};

Head.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default Head;
