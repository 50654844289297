import styled from 'styled-components';

import '@/components/all.sass';

const Styled = {};

Styled.Root = styled.div`
  font-family: ${props => props.theme.font.fontFamily};
`;

export default Styled;
