import styled from 'styled-components';

const Styled = {};

Styled.Header = styled.header`
  padding: 20px;
`;

Styled.Title = styled.h1`
  padding: 0;
  color: ${props => props.theme.color.primary};
  font-weight: lighter;
  font-size: ${props => props.theme.font.size2};

  @media ${props => props.theme.device.below.tablet} {
    font-size: ${props => props.theme.font.size3};
  }
`;

Styled.HeroImage = styled.img`
  padding: 0;
  width: 100%;
  margin-top: 20px;
  border-radius: 12px;
  box-shadow: 0 0 4px rgba(0, 0, 0, .2);
  position: relative;
`;

export default Styled;
